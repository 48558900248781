.imageLogo {
   width: 100%;
}

.centerButtons {
   display: flex;
   justify-content: center;
   align-items: center
}

.circleButton {
   margin: 0px;
}

.typographyOperations{
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.likedButtons{
   display: flex;
   justify-content: center;
   align-items: center;
}

.no-select-user{
   user-select: none;
}

.see-detail {
   display: flex;
   justify-content: center;
   align-items: center;
}